import { MissionService } from "@/services";
import { TMissionUser } from "@/types";
import { ImmerStateCreator } from "./useStore";

export type MissionSlice = {
  MyMissionList: TMissionUser[];
  MyMissionListLoading: boolean;
  fetchMyMissionList: () => Promise<void>;
};

export const createMissionSlice: ImmerStateCreator<MissionSlice> = (set) => ({
  MyMissionList: [],
  MyMissionListLoading: false,

  fetchMyMissionList: async () => {
    set((state) => {
      state.MyMissionListLoading = true;
    });

    const res = await MissionService.getMyMissionList();
    set((state) => {
      state.MyMissionList = res.metadata;
    });

    set((state) => {
      state.MyMissionListLoading = false;
    });
  },
});

import { AUTH, ROUTE } from "@/const/path.const";
import { useStore } from "@/store/useStore";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchUser } = useStore();

  useEffect(() => {
    // Check if the user is logged in
    if (!location.pathname.includes(ROUTE.CONNECT_WALLET)) {
      const token = localStorage.getItem("token");
      if (token) {
        fetchUser()
          .then((user) => {
            const walletList = user.metadata?.list_wallet || [];
            const isNewUser = Boolean(user.metadata?.is_newUser);
            if (walletList.length === 0 && !isNewUser) {
              navigate(AUTH.WALLET_OPTIONS.CONNECT);
            }
          })
          .catch(() => {
            localStorage.removeItem("token");
            navigate("/login");
          });
      } else {
        navigate("/login");
      }
    }
  }, []);
};

export { useAuth };

import React from "react";

interface AvatarProps extends React.ComponentPropsWithRef<"img"> {
  border?: boolean;
  alt?: string;
  type?: "circle" | "square";
}

export function Avatar({ border = false, type = "circle", src, alt, className, style }: AvatarProps) {
  return (
    <img
      src={src}
      alt={alt}
      style={style}
      className={`${type === "circle" && "rounded-full"} 
      ${type === "square" && "rounded-xl"} ${border && "border-2 border-white border-solid"} ${className}`}
    />
  );
}

import { useEffect, useState } from "react";
import { PostService } from "@/services";
import { TTag } from "@/types";
import Drawer from "@/components/common/Drawer/Drawer";
import ButtonTag from "./ButtonTag";
import { useStore } from "@/store/useStore";

interface SelectTagsProps {
  isOpen: boolean;
  onClose: () => void;
  isBreakpoint?: boolean;
}

const SelectTags: React.FC<SelectTagsProps> = ({ isOpen, onClose, isBreakpoint }) => {
  const { tags, setTags, selectedTags, setSelectedTags, setContextTags, resetSelectedTags, resetContextTags } =
    useStore();

  const getAllTags = async () => {
    try {
      const data = await PostService.fetchAllTags();
      setTags(data.metadata);

      if (isBreakpoint) {
        const tag = data.metadata.find((item) => item.id === "cm0wbqhct07lqijpejsjwuo2l");
        if (tag) {
          setSelectedTags([...selectedTags, tag]);
          setContextTags(`#${tag.name}`);
        }
      } else {
        resetSelectedTags();
        resetContextTags();
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  const handleSelectedTag = () => {
    if (selectedTags.length > 0) {
      setSelectedTags(selectedTags);
      const selectedTagContext = selectedTags.map((tag) => `#${tag.name}`).join(" ");
      setContextTags(selectedTagContext);
      onClose();
    }
  };

  const handleClickTag = (tag: TTag) => {
    const tagExists = selectedTags.some((selected) => selected.id === tag.id);
    const updatedTags = tagExists ? selectedTags.filter((selected) => selected.id !== tag.id) : [...selectedTags, tag];

    setSelectedTags(updatedTags);

    if (updatedTags.length === 0) {
      resetSelectedTags();
      resetContextTags();
    }
  };

  return (
    <Drawer isOpen={isOpen} setIsOpen={onClose} isCloseable={false}>
      <div className="w-full h-full flex flex-col justify-between">
        <div className="flex flex-col gap-5">
          <p className="text-[#757575]">Select relevant tag for your image {`>`}</p>
          <div className="h-[70%] flex gap-3 flex-wrap">
            {tags.map((item: TTag) => (
              <ButtonTag key={item.id} tag={item} selectedTags={selectedTags} onClick={handleClickTag} />
            ))}
          </div>
        </div>
        <button
          className={`rounded-lg py-4 w-full font-medium ${
            selectedTags.length > 0 ? "bg-[#99FF48] text-black" : "bg-[#434343] text-[#F5F5F5]"
          } my-[10%]`}
          onClick={handleSelectedTag}
          disabled={selectedTags.length === 0}
        >
          Select
        </button>
      </div>
    </Drawer>
  );
};

export default SelectTags;

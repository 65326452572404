import { useMemo } from "react";

export function StepDotBar({ step }: { step: number }) {
  const classStep = useMemo(() => {
    switch (step) {
      case 0:
        return "left-0";
      case 1:
        return "left-1/2 -translate-x-1/2";
      case 2:
        return "right-0";
      default:
        return "left-0";
    }
  }, [step]);

  return (
    <div className="w-full flex items-center justify-center mt-4 mb-9">
      <div className="w-[100px] h-6 flex items-center justify-between relative">
        <div
          className={`absolute ${classStep} flex items-center justify-center rounded-full w-6 h-6 border-2 border-[#D9D9D9] transition-all duration-300`}
        >
          <span className="p-[5px] bg-[#D9D9D9] rounded-full" />
        </div>
        {[1, 2, 3].map((i) => (
          <div key={i} className="w-4 h-4 rounded-full border-2 border-[#303030]" />
        ))}
      </div>
    </div>
  );
}

import { BackButton, Button } from "@/components/common";
import { ROUTE } from "@/const/path.const";
import { PostService } from "@/services";
import { TPost } from "@/types";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa";
import { Link } from "react-router-dom";
import MintBreakpoint from "../components/MintBreakpoint";
import BreakpointPost from "./BreakpointPost";

function BreakpointPage() {
  const [posts, setPosts] = useState<TPost[]>([]);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isFetchingPost, setIsFetchingPost] = useState(false);

  const fetchBreakPointPosts = async (page: number) => {
    setIsFetchingPost(true);
    const queryParam = generatorParams(page);

    const posts = await PostService.getAllPost(queryParam);
    if (posts.metadata) {
      setTotalItem(posts.metadata.pagination.total);
      setPosts((pre) => pre.concat(posts.metadata.data));
    }
    setIsFetchingPost(false);
  };

  const generatorParams = (selectedPage: number) => {
    return {
      page: selectedPage,
      limit: 20,
      tags: {
        some: {
          tagId: {
            in: ["cm0wbqhct07lqijpejsjwuo2l"],
          },
        },
      },
    };
  };

  const handleLoadMorePosts = () => {
    // Skip if fetching server or not have any post
    if (false === isFetchingPost || false === hasMorePost) {
      setCurrentPage((page) => page + 1);
    }
  };

  useEffect(() => {
    fetchBreakPointPosts(currentPage);
  }, [currentPage]);

  const hasMorePost = totalItem > 0 && posts.length < totalItem;

  return (
    <section className="relative w-full h-screen">
      <div
        className="absolute w-full h-full -z-10"
        style={{ background: "linear-gradient(180deg, rgba(43,78,31,1) 0%, rgba(0,0,0,1) 10rem)" }}
      />
      <div className="w-full flex flex-col items-center">
        <div className="w-full flex justify-between items-center px-5 pt-5">
          <BackButton />
          <div className="w-full text-xl text-center">Breakpoint Gallery 2024</div>
          {/* <Link to={ROUTE.CAPTURE.TAKE}>
            <Button
              icon={<FaCamera size={20} />}
              className="!rounded-full justify-center !bg-[#3D3D3D]/50 text-sm"
            ></Button>
          </Link> */}
        </div>
        <a
          href="https://x.com/treknodysseynft/status/1836672146330005820?s=46&t=6KkFRIO8g292lCk1A6jYrA"
          target="blank"
          className="w-[90%] flex justify-center items-center gap-3 py-2 px-6 my-5 rounded-full border-2 border-[#303030]"
        >
          <FontAwesomeIcon icon={faCircleInfo} className="w-4 h-4 text-gray-500" />
          <span>View more info here</span>
        </a>
        <div className="mt-5 w-screen px-3">
          <MintBreakpoint />
        </div>
        <div className="mt-10 w-full pb-28">
          {posts.length > 0 ? (
            <BreakpointPost posts={posts} hasMore={hasMorePost} onLoadMore={handleLoadMorePosts} />
          ) : (
            <div className="flex items-center justify-center">
              <p className="h-10 font-thin text-white opacity-70">Be the first one mint a proof</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default BreakpointPage;

import { HorizontalScroll, SmallPost } from "@/components/common";
import { EXPLORE } from "@/const/path.const";
import { TPost } from "@/types";
import { Link } from "react-router-dom";

export function CommunityPost({ data, communityId }: { data: TPost[]; communityId: string }) {
  return (
    <div className="px-1">
      <div className="flex items-center justify-between">
        <p className="text-gray-1 text-small">Today</p>
        <Link
          to={`${EXPLORE.COMMUNITIES}?type=community&communityId=${communityId}`}
          className="text-gray-4 text-small"
        >
          View all
        </Link>
      </div>
      <div className="mt-4">
        {data && (
          <HorizontalScroll>
            {data.map((item) => (
              <SmallPost key={item.id} item={item} />
            ))}
          </HorizontalScroll>
        )}
      </div>
    </div>
  );
}

import { Avatar } from "@/components/common";
import { useStore } from "@/store/useStore";
import { TreknLogoImg } from "public/Images";
import { useEffect } from "react";
import { LeaderBoardCard } from "./LeaderBoardCard";

export default function CommunityLeaderboard() {
  const { CommunityLeaderboard, fetchLeaderboard } = useStore();

  useEffect(() => {
    fetchLeaderboard();
  }, []);
  return (
    <div className="w-full p-4">
      <div
        className="p-1 rounded-xl flex items-center space-x-2"
        style={{ background: "linear-gradient(90deg, rgba(118,187,63,1) 0%, rgba(44,44,44,1) 50%)" }}
      >
        <Avatar src={TreknLogoImg} type="square" className="w-14 h-14 bg-black" />
        <p className="text-small">Top 30 communities will share 20% of the token airdrop.</p>
      </div>
      <div className="mt-8 h-[30rem] overflow-y-auto">
        <div className="space-y-3">
          {CommunityLeaderboard.map((item, index) => (
            <LeaderBoardCard key={item.id} icon={item.avatar} name={item.name} points={item.points} index={index + 1} />
          ))}
        </div>
      </div>
    </div>
  );
}

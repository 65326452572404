import React from "react";
import { LoadingSpinner } from "../LoadingSpinner";

interface ButtonProps extends React.ComponentPropsWithRef<"button"> {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  size?: "sm" | "md" | "lg";
  loading?: boolean;
}

export function Button({
  children,
  icon,
  className,
  style,
  disabled,
  loading = false,
  size = "md",
  onClick,
}: ButtonProps) {
  const getSize = () => {
    switch (size) {
      case "sm":
        return "py-2 px-4";
      case "md":
        return "py-4 px-6";
      case "lg":
        return "py-6 px-8";
    }
  };

  return (
    <button
      onClick={onClick}
      className={`bg-secondary rounded-lg flex items-center shadow shadow-slate-800 ${getSize()} ${className} ${
        disabled && "opacity-50 cursor-not-allowed"
      }`}
      style={style}
      disabled={disabled}
    >
      {icon && <span className={`${!!children && "w-7"}`}>{icon}</span>}
      {loading ? <LoadingSpinner /> : children}
    </button>
  );
}

import { PostService } from "@/services";
import { TPost } from "@/types";
import { ImmerStateCreator } from "./useStore";

export type HomePostsSlice = {
  HomeAllPost: TPost[];
  HomeMyPost: TPost[];
  HomeFriendPost: TPost[];
  HomeClaimPost: TPost[];
  HomeLoading: boolean;
  fetchHomePosts: () => Promise<void>;
};

export const createHomePostsSlice: ImmerStateCreator<HomePostsSlice> = (set) => ({
  HomeAllPost: [],
  HomeClaimPost: [],
  HomeMyPost: [],
  HomeFriendPost: [],
  HomeLoading: false,

  fetchHomePosts: async () => {
    set((state) => {
      state.HomeLoading = true;
    });
    const [MyPost, AllPost, ClaimPost, FriendPosts] = await Promise.all([
      PostService.getMyPost({ page: 1, limit: 10 }),
      PostService.getAllPost({ page: 1, limit: 10 }),
      PostService.getPostInDayAndUnclaim(),
      PostService.getFriendPost({ page: 1, limit: 10 }),
    ]);
    set((state) => {
      state.HomeMyPost = MyPost.metadata?.data;
      state.HomeAllPost = AllPost.metadata?.data;
      state.HomeClaimPost = ClaimPost?.metadata;
      state.HomeFriendPost = FriendPosts.metadata?.data;
      state.HomeLoading = false;
    });
  },
});

import API, { ApiResponse } from "../config";

class CreateWhitelistService {
  async checkIsWhitelist() {
    const res = await API.GET<ApiResponse<boolean>>("/whitelist/check");
    return res.metadata;
  }
  async buyWhitelist() {
    const res = await API.POST<ApiResponse<boolean>>("/whitelist/buy",{});
    return res.metadata;
  }
}

const WhitelistService = new CreateWhitelistService();
export { WhitelistService };

import { HOME } from "@/const/path.const";
import { AwardBp } from "public/Images";
import { useNavigate } from "react-router-dom";

export default function BreakpointAward() {
  const navigate = useNavigate();
  return (
    <div className="relative h-screen px-4 bg-gradient-to-t from-[#2B0B4C] to-[#460DC0] flex flex-col items-center">
      <p className="mt-10 mb-5 text-[#FFFFFF66]">Congratulation! Let’s check your wallet</p>
      <img alt="BreakPoint" src={AwardBp} className="object-cover object-center" />
      <div
        onClick={() => navigate(HOME.ROOT)}
        className="absolute bottom-10 right-4 left-4 py-4 flex justify-center bg-[#1E1E1E] border border-[#2C2C2C]"
      >
        Done
      </div>
    </div>
  );
}

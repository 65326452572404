import { useEffect, useState } from "react";
import styles from "./MenuPost.module.css";
import { TReport } from "@/types";
import { useStore } from "@/store/useStore";
import { PostService } from "@/services";

export default function MenuPost({
  handleClose,
  isReported,
  handleReportPost,
}: {
  handleClose: () => void;
  isReported: boolean;
  handleReportPost: ()=>void;
}) {
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);
  const { user } = useStore();

  const handleCloseWithAnimation = () => {
    setIsAnimatingOut(true);
    setTimeout(() => {
      handleClose();
    }, 300);
  };

  return (
    <div className={`p-10 absolute top-0 right-0 bottom-0 left-0`} onClick={handleCloseWithAnimation}>
      <div
        className={`absolute right-0 bg-[#cccccc] rounded-xl ${styles["menu-post"]} ${
          isAnimatingOut ? `${styles["fade-out"]}` : `${styles["fade-in"]}`
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={`py-2.5 px-4 cursor-pointer ${isReported && "opacity-50 pointer-events-none"}`}
          onClick={handleReportPost}
        >
          <p className="text-[#FF3B30]">Report this post</p>
        </div>
      </div>
    </div>
  );
}

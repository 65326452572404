import React from "react";
import { LoadingSpinner } from "../LoadingSpinner";

interface LinearButtonProps extends React.ComponentPropsWithRef<"button"> {
  children?: React.ReactNode;
  loading?: boolean;
}

export function LinearButton({
  children,
  className,
  style,
  onClick,
  loading = false,
  disabled = false,
}: LinearButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${className} rounded-xl text-medium py-4 px-6 transition-opacity duration-300 ${
        disabled && "opacity-50 cursor-not-allowed"
      }`}
      style={{ background: "linear-gradient(90deg, rgba(121,200,57,1) 0%, rgba(2,76,0,1) 100%)", ...style }}
    >
      {loading && <LoadingSpinner className="mr-2" />}
      {children}
    </button>
  );
}

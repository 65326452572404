import { BackButton, Button, CircleLoading } from "@/components/common";
import { CommunityService, PostService } from "@/services";
import { useStore } from "@/store/useStore";
import { TPost } from "@/types";
import { TCommunity } from "@/types/community.type";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CommunityInfo, CommunityPost, RewardCard } from "../components";

export function FanpagePage() {
  const { user } = useStore();
  const [, notificationOccurred] = useHapticFeedback();
  const { fanpageId } = useParams<{ fanpageId: string }>();
  const [loading, setLoading] = useState(true);
  const [fanpage, setFanpage] = useState<TCommunity>();
  const [posts, setPosts] = useState<TPost[]>([]);
  const [followMember, setFollowMember] = useState({ follow: false, member: false });

  const fetchFanpage = async () => {
    if (user) {
      setLoading(true);
      const [resCommunity, resPosts, checkFollowAndMember] = await Promise.all([
        CommunityService.getCommunity(fanpageId!),
        PostService.getPostByCommunityId(fanpageId!, { page: 1, limit: 10 }),
        CommunityService.checkFollowAndMember(fanpageId!),
      ]);

      setFanpage(resCommunity.metadata);
      setPosts(resPosts.metadata.data.flatMap((item) => item.post));
      setFollowMember(checkFollowAndMember.metadata);
      setLoading(false);
    }
  };

  const handleFollowOrUnFollow = async () => {
    if (followMember.follow) {
      const res = await CommunityService.unFollowCommunity(fanpageId!);
      if (res.success) {
        setFollowMember({ ...followMember, follow: false });
        notificationOccurred("success");
      } else {
        notificationOccurred("error");
        alert("Something went wrong, please try again later!");
      }
    } else {
      const res = await CommunityService.followCommunity(fanpageId!);
      if (res.success) {
        setFollowMember({ ...followMember, follow: true });
        notificationOccurred("success");
      } else {
        notificationOccurred("error");
        alert("Something went wrong, please try again later!");
      }
    }
  };

  useEffect(() => {
    fetchFanpage();
  }, [user]);

  return (
    <section className="p-4">
      <div className="flex items-center justify-between mt-2">
        <BackButton />
        {!loading && (
          <Button onClick={handleFollowOrUnFollow} className="items-center !rounded-full !bg-main !px-8">
            {followMember.follow ? "Following" : "Follow"}
          </Button>
        )}
      </div>
      {loading && (
        <div className="flex items-center justify-center w-full">
          <CircleLoading className="mt-8" />
        </div>
      )}
      {fanpage && (
        <>
          <div className="mt-8">
            <CommunityInfo isMember={followMember.member} data={fanpage} />
          </div>
          {followMember.member && <RewardCard />}
          <div className="mt-8">
            <CommunityPost communityId={fanpage.id} data={posts} />
          </div>
        </>
      )}
    </section>
  );
}

import { TPost, TTag } from "@/types";
import API, { ApiResponse, TPagination } from "../config";
import { TParamReq } from "@/utils/api.utils";
import qs from "qs";
import { CaptureFormInterface } from "@/context/CaptureContext";

type ResAllPostByCommunityId = ApiResponse<{
  data: {
    post: TPost[];
    communityId: number;
    postId: number;
    createdAt: string;
    updatedAt: string;
  }[];
  pagination: TPagination;
}>;

type ResAllPost = ApiResponse<{
  data: TPost[];
  pagination: TPagination;
}>;

class CreatePostService {
  async getPostByCommunityId(id: string, params: TParamReq) {
    return API.GET<ResAllPostByCommunityId>(`/post/community/${id}?${qs.stringify(params)}`);
  }

  async getAllPost(params: TParamReq) {
    return API.GET<ResAllPost>(`/post?${qs.stringify(params)}`);
  }

  async getMyPost(params: TParamReq) {
    return API.GET<ResAllPost>(`/user/posts?${qs.stringify(params)}`);
  }

  async getFriendPost(params: TParamReq) {
    return API.GET<ResAllPost>(`/post/me/friendPosts?${qs.stringify(params)}`);
  }

  async createPost(body: CaptureFormInterface) {
    return API.POST<ApiResponse<any>>(`/post`, body);
  }

  async getPostById(id: string) {
    return API.GET<ApiResponse<TPost>>(`/post/${id}`);
  }

  async claimPost(post_id: string, user_id: string) {
    return API.POST<ApiResponse<any>>(`/point/earn/${user_id}/post/${post_id}`);
  }

  async getPostInDayAndUnclaim() {
    return API.GET<ApiResponse<TPost[]>>(`/post/me/postInDay-unclaimed`);
  }

  async donateViaPost(post_id: string, receiver_id: string, amount: number) {
    return await API.POST<ApiResponse<TPost>>(`/donate/donate-via-post`, { post_id, receiver_id, amount });
  }

  async report(data: { postId: string; userId: string }) {
    return await API.POST(`/post/report`, data);
  }

  async getListNft(address: string) {
    const myHeaders = new Headers();
    myHeaders.append("x-api-key", process.env.REACT_APP_SHYFT_API_KEY!);

    return fetch(`https://api.shyft.to/sol/v2/nft/read_all?network=mainnet-beta&address=${address}`, {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then(({ result }: any) => {
        return result;
      })
      .catch((error) => {
        console.log("error", error);
        return [];
      });
  }

  async getNftNoBg(data: { imageUrl: string; mintAddress: string }) {
    return await API.POST("/nft", data);
  }

  async fetchAllTags() {
    return API.GET<ApiResponse<TTag[]>>("/tag");
  }

  async getTagById(id: string) {
    return API.GET<ApiResponse<TTag>>(`/tag/${id}`);
  }
}

const PostService = new CreatePostService();
export { PostService };

import { useCaptureContext } from "@/context/CaptureContext";
import { useEffect, useRef } from "react";
import CaptureCamera from "./components/Camera";
import CaptureFooter from "./components/CaptureFooter";
import CaptureHeader from "./components/CaptureHeader";
import CaptureImage from "./components/CaptureImage";
import ReactGA from "react-ga4";

export default function CaptureTake() {
  const cameraRef = useRef<any>(null);
  const { form } = useCaptureContext();

  useEffect(()=>{
    ReactGA.event({
      category: "App Engagement",
      action: "checkin",
      label: "Go to checkin"
    });
    if (cameraRef.current) {
      cameraRef.current.click();
    }
  },[])

  return (
    <div className="min-h-screen px-4 py-5">
      <CaptureHeader />
      <div className="bg-main bg-opacity-60 h-[50vh] w-full rounded-xl overflow-hidden my-5 relative">
        {form.image ? <CaptureImage /> : <CaptureCamera ref={cameraRef} />}
      </div>
      <CaptureFooter cameraRef={cameraRef} />
    </div>
  );
}

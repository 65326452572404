const SkeletonSmallPost = () => {
  return (
    <>
      {[1, 2, 3].map((i) => (
        <div key={i} className="w-[9.375rem] h-[10.625rem] bg-gray-400/30 inline-block animate-pulse rounded-md" />
      ))}
    </>
  );
};

export { SkeletonSmallPost };

import { LoadingSpinner } from "@/components/common";
import Drawer from "@/components/common/Drawer/Drawer";
import { WhitelistService } from "@/services";
import { useStore } from "@/store/useStore";
import { TreknLogoImg } from "public/Images";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

export default function WhitelistBanner() {
  const { user, addPoints } = useStore();
  const [isEnable, setIsEnable] = useState<boolean>(false);
  const [isClaimed, setIsClaimed] = useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [claimLoading, setClaimLoading] = useState<boolean>(false);

  useEffect(() => {
    checkIsWhitelist();
  }, []);

  const checkIsWhitelist = async () => {
    const res = await WhitelistService.checkIsWhitelist();
    setIsClaimed(res);
    if (user && user?.points >= 10000 && !res) {
      setIsEnable(true);
    }
  };

  const handleClickToBanner = () => {
    ReactGA.event({
      category: "App Engagement",
      action: "tap_banner_wl",
      label: "Tap Banner WhiteList",
    });

    if (isEnable) {
      setIsOpenDrawer(true);
    }
  };

  const handleClaim = async () => {
    setClaimLoading(true);
    const res = await WhitelistService.buyWhitelist();
    if (res && user) {
      addPoints(-10000)
      setIsClaimed(true);
      setIsEnable(false);
      setIsOpenDrawer(false);
      setClaimLoading(false);
    }
  };

  const closeDrawer = () => setIsOpenDrawer(false);

  const renderClaimStatus = () => {
    if (isEnable) {
      return "Claim with 10,000 points";
    }
    return !isClaimed ? "All distributed, come back tomorrow" : "You’ve claimed";
  };

  const DrawerBurnPoint = () => (
    <Drawer isOpen={isOpenDrawer} setIsOpen={closeDrawer} className="!h-[45vh] !pb-10">
      <div className="flex flex-col items-center flex-grow h-full">
        <img src={TreknLogoImg} alt="trekn-logo" className="w-[60px] h-[60px]" />
        <p className="mt-6 mb-8 text-[24px] leading-[28.5px] font-medium text-[#F5F5F5]">Burn 10,000 points</p>
        <div className="flex items-center gap-1">
          <img src={TreknLogoImg} alt="trekn-logo" className="w-5 h-5 border border-[#FFE248] rounded-full" />
          <p className="text-xl leading-7 text-white font-semibold">Whitelist spot</p>
        </div>
        <div
          className="w-full rounded-full py-4 bg-gradient-to-l from-[#024C00] to-[#99FF48] mt-auto flex items-center justify-center"
          onClick={handleClaim}
        >
          {claimLoading ? (
            <LoadingSpinner />
          ) : (
            <p className="text-[16px] leading-[22.4px] font-semibold text-[#F5F5F5]">Claim whitelist spot</p>
          )}
        </div>
      </div>
    </Drawer>
  );

  return (
    <>
      <DrawerBurnPoint />
      <div
        className="w-full relative text-center border-[1px] rounded-3xl border-[#60F13B] my-5 px-4 py-6 bg-cover bg-center bg-no-repeat flex items-center justify-center"
        style={{ background: "url('./Images/Background/presale_background.png')" }}
        onClick={handleClickToBanner}
      >
        <div className="flex flex-col justify-center items-center gap-5 font-['Helvetica Neue'] w-full">
          <div className="w-full flex flex-col gap-3">
            <p className="text-[16px] leading-[22.4px] tracking-[0.5vw] font-bold">Trekn Odyssey NFT Whitelist</p>
            <p className="text-sm font-light text-[#DBE2FCB2]">Gain access to whitelist stage</p>
            <a href="https://x.com/TreknOdysseyNFT" target="blank" className="font-light text-sm text-[#99FF48]">
              View more details
            </a>
          </div>
          <div
            className={`${
              isEnable ? "border-[#60F13B] text-[#AEFF91] bg-[#1A3613]" : "border-[#A0A0A0] text-[#A0A0A0] bg-[#303030]"
            } w-full rounded-full py-2 border-[1px] text-medium font-light`}
          >
            {renderClaimStatus()}
          </div>
        </div>
      </div>
    </>
  );
}

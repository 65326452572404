import { BottomSheet, LinearButton } from "@/components/common";
import { FRIEND_CONFIRM_STATUS } from "@/const/app.const";
import { FriendRefService } from "@/services";
import { UserService } from "@/services/user/user.service";
import { useStore } from "@/store/useStore";
import { TUser } from "@/types";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useEffect, useState } from "react";
import { FaTelegram } from "react-icons/fa";

export function AcceptFriendModal({ open, setOpen }: { open: boolean; setOpen: (value: boolean) => void }) {
  const { user } = useStore();
  const [, notificationOccurred] = useHapticFeedback();
  const [friend, setFriend] = useState<TUser | null>(null);

  const getInfoFriend = async () => {
    const friend = await UserService.getUser(JSON.parse(localStorage.getItem("refFriend")!).id);
    if (friend.success) {
      setFriend(friend.metadata);
    } else {
      setOpen(false);
      alert("Something went wrong, please try again later!");
    }
  };

  const handleRemoveRF = () => {
    setOpen(false);
    const rf = localStorage.getItem("refFriend");
    if (rf) {
      const rf_data = JSON.parse(rf);
      rf_data.status = FRIEND_CONFIRM_STATUS.REJECTED;
      localStorage.setItem("refFriend", JSON.stringify(rf_data));
    }
  };

  const handleAddFriend = async () => {
    const makeFriend = await FriendRefService.makeFriend(JSON.parse(localStorage.getItem("refFriend")!).id);
    if (makeFriend.success) {
      notificationOccurred("success");
      const rf = localStorage.getItem("refFriend");
      if (rf) {
        const rf_data = JSON.parse(rf);
        rf_data.status = FRIEND_CONFIRM_STATUS.ACCEPTED;
        localStorage.setItem("refFriend", JSON.stringify(rf_data));
      }
      setOpen(false);
    } else {
      notificationOccurred("error");
      alert("Something went wrong, please try again later!");
      setOpen(false);
    }
  };

  useEffect(() => {
    if (
      user &&
      localStorage.getItem("refFriend") &&
      JSON.parse(localStorage.getItem("refFriend")!).status === FRIEND_CONFIRM_STATUS.PENDING
    ) {
      getInfoFriend();
    }
  }, [user]);

  return (
    <BottomSheet open={open} onClose={handleRemoveRF} showHeader={false} height={[450]}>
      <div className="flex flex-col w-[90%] items-center justify-center my-6 mx-6">
        <FaTelegram className="w-16 h-16" color="#4D4D4D" />
        <p className="text-center text-base text-brand-on_brand mt-3">Add friend with {friend?.name}?</p>
        <LinearButton className="mt-12 !w-full !rounded-full" onClick={handleAddFriend}>
          Add friend
        </LinearButton>
      </div>
    </BottomSheet>
  );
}

/* eslint-disable no-restricted-globals */
import { BottomSheet, Button } from "@/components/common";
import { UserService } from "@/services/user/user.service";
import { TListWallet, WALLET_IMAGE, WALLET_NAME } from "@/types";
import { decryptData } from "@/utils/blockchain.utils";
import { maskString } from "@/utils/common.utils";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useState } from "react";
import { FaKey, FaTrash } from "react-icons/fa";
import { LuClipboard, LuClipboardCheck } from "react-icons/lu";

enum WalletSettingState {
  DEFAULT = "DEFAULT",
  EXPORT_PRIVATE_KEY = "EXPORT_PRIVATE_KEY",
  DISCONNECT_WALLET = "DISCONNECT_WALLET",
}

enum HeightPoint {
  DISCONNECT_WALLET = 0,
  EXPORT_PRIVATE_KEY = 0,
  DEFAULT = 1,
  CLOSE = 2,
}

type WalletSettingProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  wallet: TListWallet;
};

const privacyOptions = [
  {
    id: 1,
    title: "Export private key",
    icon: <FaKey size={18} />,
    danger: false,
    type: WALLET_NAME.CREATE,
    to: WalletSettingState.EXPORT_PRIVATE_KEY,
    message:
      "WARNING: This Private Key is the credential to your assets. DO NOT lose it or reveal it to others, otherwise you might lose your assets forever. Please view it in a secure environment and keep it carefully!",
  },
  {
    id: 2,
    title: "Disconnect wallet",
    icon: <FaTrash size={18} />,
    danger: true,
    type: null,
    to: WalletSettingState.DISCONNECT_WALLET,
    message:
      "WARNING: This wallet cannot be restored after disconnection. To regain access, you must reconnect through a different wallet application or establish a new wallet!",
  },
];

export function WalletSetting({ open, setOpen, wallet }: WalletSettingProps) {
  const [notification] = useHapticFeedback();
  const [snap, setSnap] = useState<number>(HeightPoint.DEFAULT);
  const [state, setState] = useState<WalletSettingState>(WalletSettingState.DEFAULT);

  const handleChangeState = async (state: WalletSettingState, message: string) => {
    if (confirm(message)) {
      notification("light");
      setState(state);
      if (state === WalletSettingState.EXPORT_PRIVATE_KEY) {
        setSnap(HeightPoint.EXPORT_PRIVATE_KEY);
      } else if (state === WalletSettingState.DISCONNECT_WALLET) {
        setSnap(HeightPoint.DISCONNECT_WALLET);
      }
    }
  };

  const handleBackDefault = () => {
    notification("light");
    setState(WalletSettingState.DEFAULT);
    setSnap(HeightPoint.DEFAULT);
  };

  const handleClose = () => {
    setSnap(HeightPoint.CLOSE);
    setOpen(false);
    setState(WalletSettingState.DEFAULT);
  };

  return (
    <BottomSheet initSnap={HeightPoint.DEFAULT} snapTo={snap} open={open} onClose={handleClose} height={[800, 600, 0]}>
      <div className="flex flex-col items-center justify-center space-y-2">
        <img className="w-14 h-14 rounded-full" src={WALLET_IMAGE[wallet.walletName]} alt="wallet logo" />
        <p>{wallet.name}</p>
        <p className="text-gray-500">{maskString(wallet.address)}</p>
      </div>
      {state === WalletSettingState.DEFAULT && (
        <DefaultState key={WalletSettingState.DEFAULT} onChange={handleChangeState} wallet={wallet} />
      )}
      {state === WalletSettingState.EXPORT_PRIVATE_KEY && (
        <ExportPrivateKey key={WalletSettingState.EXPORT_PRIVATE_KEY} wallet={wallet} onBack={handleBackDefault} />
      )}
      {state === WalletSettingState.DISCONNECT_WALLET && (
        <DisconnectWallet key={WalletSettingState.DISCONNECT_WALLET} onBack={handleBackDefault} wallet={wallet} />
      )}
    </BottomSheet>
  );
}

const DefaultState = ({
  onChange,
  wallet,
}: {
  onChange: (state: WalletSettingState, message: string) => void;
  wallet: TListWallet;
}) => {
  return (
    <div className="px-6 mt-4 space-y-3">
      <h3 className="font-medium">Privacy:</h3>
      {privacyOptions
        .filter((option) => (wallet.walletName === WALLET_NAME.CREATE ? true : option.type !== WALLET_NAME.CREATE))
        .map((option) => (
          <Button
            onClick={() => onChange(option.to, option.message)}
            className={`w-full ${option.danger && "!bg-red-700"}`}
            icon={option.icon}
          >
            {option.title}
          </Button>
        ))}
    </div>
  );
};

const ExportPrivateKey = ({ wallet, onBack }: { wallet: TListWallet; onBack: () => void }) => {
  const [, notificationOccurred] = useHapticFeedback();
  const [copy, setCopy] = useState(false);
  const wallets = JSON.parse(localStorage.getItem("wallets") || "[]");
  const privateKey = wallets.find((w: TListWallet) => w.id === wallet.id);

  const handleCopySeeds = async () => {
    if (!privateKey) return;
    notificationOccurred("success");
    setCopy(true);
    await navigator.clipboard.writeText(decryptData(privateKey.secret));
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };

  return (
    <div className="space-y-2 px-6 mt-4">
      <h3 className="font-medium">Private Key:</h3>
      {privateKey ? (
        <div className="flex flex-col items-end">
          <div className="w-full p-2 border border-red-700 rounded-md">
            <p className="break-words">{decryptData(privateKey.secret)}</p>
          </div>
          <button
            onClick={handleCopySeeds}
            className="py-2 px-4 mt-2 bg-button rounded-md space-x-1 flex items-center w-28"
          >
            {copy ? <LuClipboardCheck size={24} className="inline" /> : <LuClipboard size={24} className="inline" />}
            <span className="text-white text-center block w-full">{copy ? "Copied" : "Copy"}</span>
          </button>
        </div>
      ) : (
        <p className="text-red-500 break-words">
          Private key not found! You can only export the private key from the device that created this wallet.
        </p>
      )}
      <Button onClick={onBack} size="md" className="w-full justify-center !mt-14">
        BACK
      </Button>
    </div>
  );
};

const DisconnectWallet = ({ onBack, wallet }: { onBack: () => void; wallet: TListWallet }) => {
  const [loading, setLoading] = useState(false);

  const handleDisconnect = async () => {
    setLoading(true);
    const res = await UserService.disconnectWallet(wallet.id);
    if (res.success) {
      const wallets = JSON.parse(localStorage.getItem("wallets") || "[]");
      const newWallets = wallets.filter((w: TListWallet) => w.id !== wallet.id);
      localStorage.setItem("wallets", JSON.stringify(newWallets));
      window.location.reload();
    } else {
      alert("Something went wrong! Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="px-6 mt-8">
      <h3 className="text-xl text-center text-red-600 font-bold">Disconnect Wallet</h3>
      <p className="mt-4">Are you sure you want to disconnect this wallet?</p>
      <div className="flex justify-between space-x-2 mt-6">
        <Button disabled={loading} onClick={onBack} className="w-1/2 justify-center">
          Cancel
        </Button>
        <Button
          onClick={handleDisconnect}
          disabled={loading}
          loading={loading}
          className="w-1/2 justify-center !bg-red-600"
        >
          Disconnect
        </Button>
      </div>
    </div>
  );
};

import { Card } from "@/components/common";
import { ROUTE } from "@/const/path.const";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

export function RewardCard() {
  return (
    <Card className="px-6 py-4 flex items-center rounded-xl mt-8">
      <Link to={ROUTE.CAPTURE.TAKE} className="flex-1">
        <p>Share your moment to claim</p>
        <p className="text-[#99FF48] text-small">Reward 300 points</p>
      </Link>
      <FaPlus size={20} className="text-[#99FF48]" />
    </Card>
  );
}

import { TTag } from "@/types";
import { ImmerStateCreator } from "./useStore";

export type TagSlice = {
  tags: TTag[];
  selectedTags: TTag[];
  contextTags: string;
  setTags: (tags: TTag[]) => void;
  setSelectedTags: (tags: TTag[]) => void;
  setContextTags: (context: string) => void;
  resetSelectedTags: () => void;
  resetContextTags: () => void;
};

export const createTagSlice: ImmerStateCreator<TagSlice> = (set) => ({
  tags: [],
  selectedTags: [],
  contextTags: '',

  setTags: (tags: TTag[]) => set((state) => { state.tags = tags; }),

  setSelectedTags: (tags: TTag[]) => set((state) => { state.selectedTags = tags; }),

  setContextTags: (context: string) => set((state) => { state.contextTags = context; }),

  resetSelectedTags: () => set((state) => { state.selectedTags = []; }),

  resetContextTags: () => set((state) => { state.contextTags = ''; }),
});

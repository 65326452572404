import { initCaptureForm, useCaptureContext } from "@/context/CaptureContext";
import { faEye, faSave, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import { shareOnMobile } from "react-mobile-share";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { EVENT, HOME } from "@/const/path.const";

const FunctionBtn = ({ children, onClick }: { children: ReactNode; onClick?: MouseEventHandler<HTMLDivElement> }) => {
  return (
    <div onClick={onClick} className="w-12 h-12 rounded-full flex items-center justify-center bg-main">
      {children}
    </div>
  );
};

export default function CaptureFinish() {
  const { form, setForm } = useCaptureContext();
  const navigate = useNavigate();
  const [isBreakpoint, setIsBreakpoint] = useState(false);
  const [params, _] = useSearchParams();

  useEffect(() => {
    setIsBreakpoint(!!params.get("breakpoint"));
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: "App Engagement",
      action: "checkin_finish",
      label: "Done checkin",
    });
  });

  return (
    <div className="min-h-screen px-4 py-6">
      <div className="flex flex-col items-center justify-center mb-8 gap-y-3">
        <p className="text-gray-1 leading-medium text-medium">Captured successful</p>
      </div>
      <div>
        <div className="h-[50vh] relative w-full bg-main rounded-xl overflow-hidden">
          {form?.nft && <img src={form.nft} className="absolute w-[25vw] h-[25vw] bottom-3" />}
          <img alt="Caption" src={form.image.toString()} className="w-full h-full object-cover object-center" />
        </div>
        <div className="flex items-center justify-center mt-6 gap-4">
          <FunctionBtn
            onClick={() => {
              shareOnMobile({
                text: "Hey check Trekn",
                url: process.env.REACT_APP_TELEGRAM_PRODUCT_URL,
                title: "Trekn",
                images: [form.image.toString()],
              });
            }}
          >
            <FontAwesomeIcon icon={faShareAlt} className="w-5 h-5" />
          </FunctionBtn>
          <a download="" href={`${form.image.toString()}?force=true`}>
            <FunctionBtn>
              <FontAwesomeIcon icon={faSave} className="w-5 h-5" />
            </FunctionBtn>
          </a>
          <FunctionBtn
            onClick={() => {
              navigate("/");
            }}
          >
            <FontAwesomeIcon icon={faEye} className="w-5 h-5" />
          </FunctionBtn>
        </div>
      </div>
      <div
        className="w-full bg-main rounded-lg active:opacity-80 py-4 mt-11"
        onClick={() => {
          setForm(initCaptureForm);
          navigate(isBreakpoint ? EVENT.BREAKNPOINT_AWARD : HOME.ROOT);
        }}
      >
        <p className="text-center">Done</p>
      </div>
    </div>
  );
}

import { useState, useEffect, useRef } from "react";

function useCountUp({ start, end, duration }: { start: number; end: number; duration: number }) {
  const [count, setCount] = useState(start);
  const startTimeRef = useRef(Date.now()); // Lưu trữ thời điểm bắt đầu

  useEffect(() => {
    startTimeRef.current = Date.now(); // Khởi tạo lại thời điểm bắt đầu khi thay đổi start, end hoặc duration

    const totalSteps = Math.floor((duration * 1000) / 500);
    const increment = (end - start) / totalSteps;

    const updateCount = () => {
      const elapsed = Date.now() - startTimeRef.current; // Thời gian đã trôi qua
      const currentStep = Math.min(Math.floor(elapsed / 500), totalSteps);

      if (elapsed >= duration * 1000) {
        setCount(end);
      } else {
        const newCount = start + increment * currentStep;
        setCount(newCount >= end ? end : newCount);
      }

      if (currentStep >= totalSteps) {
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(updateCount, 500);
    updateCount(); // Cập nhật ngay lập tức

    return () => clearInterval(intervalId);
  }, [start, end, duration]);

  return count;
}

export default useCountUp;

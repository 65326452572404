import { useCaptureContext } from "@/context/CaptureContext";
import { MouseEventHandler, ReactNode, RefObject, useEffect, useState } from "react";

import { CircleLoading } from "@/components/common";
import { PostService } from "@/services";
import { useStore } from "@/store/useStore";
import { faMapPin, faPaperPlane, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CameraType } from "react-camera-pro";
import { useNavigate, useSearchParams } from "react-router-dom";
import SelectCommunity from "./SelectCommunity";
import SelectTags from "./SelectTags";

export default function CaptureFooter({ cameraRef }: { cameraRef: RefObject<CameraType> }) {
  const { form } = useCaptureContext();

  return (
    <div className="flex items-center justify-center gap-9">
      {!form.image && <BeforeTakeFooter cameraRef={cameraRef} />}
      {form.image && <TakenFooter />}
    </div>
  );
}

const MainButton = ({ children, onClick }: { children?: ReactNode; onClick?: MouseEventHandler<HTMLDivElement> }) => {
  return (
    <div onClick={onClick} className="p-1 border border-white rounded-full active:opacity-80 transition-all">
      <div className="rounded-full bg-white w-[72px] h-[72px] flex items-center justify-center">{children}</div>
    </div>
  );
};

const SubButton = ({ children, onClick }: { children: ReactNode; onClick?: MouseEventHandler<HTMLDivElement> }) => {
  return (
    <div
      onClick={onClick}
      className="rounded-full active:opacity-80 bg-main w-[72px] h-[72px] flex items-center justify-center transition-all"
    >
      {children}
    </div>
  );
};

const BeforeTakeFooter = ({ cameraRef }: { cameraRef: RefObject<CameraType> }) => {
  // const handleTakePhoto = () => {
  //   const image = cameraRef.current?.takePhoto();
  //   if (image) {
  //     setForm({ ...form, image });
  //   }
  // };

  return (
    <>
      {/* <SubButton onClick={handleTorch}>
        <UnflashIcon />
      </SubButton>
      <MainButton onClick={handleTakePhoto} />
      <SubButton onClick={handleSwitchCamera}>
        <RotateCameraIcon />
      </SubButton> */}
    </>
  );
};

const TakenFooter = () => {
  const [isOpenSelectCommunity, setIsOpenSelectCommunity] = useState<boolean>(false);
  const [isOpenSelectNFT, setIsOpenSelectNFT] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const { form } = useCaptureContext();
  const { selectedTags } = useStore();
  const navigate = useNavigate();
  const [isBreakpoint, setIsBreakpoint] = useState(false);
  const [params,_] = useSearchParams();

  useEffect(() => {
    setIsBreakpoint(!!params.get('breakpoint'));
  }, []);

  const handleSubmitPost = async () => {
    setLoading(true);
    const submitForm = {
      ...form,
      communityIds: form.communityIds.map((item) => item.id),
      tags: selectedTags.map((item) => item.id),
    };
    const res = await PostService.createPost(submitForm);
    if (res.success) {
      navigate(`/capture/finish${isBreakpoint ? '?breakpoint=true' : ''}`);
    } else {
      alert("Something went wrong, please try again later!");
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <CircleLoading />
      ) : (
        <>
          <SubButton onClick={() => setIsOpenSelectCommunity(true)}>
            {form.communityIds?.length ? (
              <div className="flex items-center gap-1">
                <p>{form.communityIds?.length}</p>
                <FontAwesomeIcon icon={faTag} className="w-4 h-4" color="#E8B931" />
              </div>
            ) : (
              <FontAwesomeIcon icon={faTag} className="w-6 h-6" />
            )}
          </SubButton>
          <MainButton onClick={handleSubmitPost}>
            <FontAwesomeIcon icon={faPaperPlane} color="black" className="w-6 h-6" />
          </MainButton>
          <SubButton onClick={() => setIsOpenSelectNFT(true)}>
            <FontAwesomeIcon
              icon={faMapPin}
              color={`${selectedTags.length > 0 ? "#E5A000" : "white"}`}
              className="w-6 h-6"
            />
          </SubButton>
          <SelectTags isOpen={isOpenSelectNFT} onClose={() => setIsOpenSelectNFT(false)} isBreakpoint={!!params.get('breakpoint')}/>
          <SelectCommunity isOpen={isOpenSelectCommunity} onClose={() => setIsOpenSelectCommunity(false)} />
        </>
      )}
    </>
  );
};

import { Button, TreknLogo } from "@/components/common";
import { AUTH, ROUTE } from "@/const/path.const";
import { deviceCheck } from "@/utils/common.utils";
import { useInitData } from "@vkruglikov/react-telegram-web-app";
import { PhantomIcon } from "public/Images";
import ReactGA from "react-ga4";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

export function WalletOptions() {
  const [initDataUnsafe] = useInitData();
  return (
    <section className="px-5 w-full h-screen">
      <div className="flex items-center justify-center w-full h-1/2">
        <TreknLogo className="translate-y-0" />
      </div>
      <div className="space-y-4">
        {/* <Link
          className="block"
          to={ROUTE.CONNECT_BITGET}
          target="_blank"
          onClick={() => {
            ReactGA.event({
              category: "Button Clicks",
              action: "connect_bitget",
              label: "Connect Bitget",
            });
          }}
        >
          <Button className="w-full !bg-main" icon={<img src={BitgetIcon} alt="Bitget Icon" className="w-5 h-5" />}>
            Bitget wallet
          </Button>
        </Link> */}
        <Link className="block" to={AUTH.WALLET_OPTIONS.CREATE}>
          <Button className="w-full !bg-main" icon={<FaPlus size={20} />}>
            Create new wallet
          </Button>
        </Link>
        <Link
          className="block"
          to={
            deviceCheck() === "desktop"
              ? `${ROUTE.DESKTOP_PHANTOM + initDataUnsafe?.user?.id}`
              : `${ROUTE.CONNECT_PHANTOM + initDataUnsafe?.user?.id + "?ref=trekn"}`
          }
          target="_blank"
          onClick={() => {
            ReactGA.event({
              category: "Button Clicks",
              action: "connect_phantom",
              label: "Connect phantom",
            });
          }}
        >
          <Button className="w-full !bg-main" icon={<img src={PhantomIcon} alt="Phantom Icon" className="w-5" />}>
            Phantom wallet
          </Button>
        </Link>
      </div>
    </section>
  );
}

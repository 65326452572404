import API, { ApiResponse } from "../config";

export class CreateCheckinService {
  async getPrize() {
    return await API.GET<ApiResponse<Array<{ prize: number | null }>>>("/checkin/prizes");
  }

  async checkIsCheckedIn(userId: string) {
    return await API.GET<ApiResponse<{ checked: boolean; data: {day: number | string;} | null }>>(`/checkin/status/${userId}`);
  }

  async checkin(userId: string) {
    return await API.POST<ApiResponse<{ checked: boolean }>>("/checkin", { userId });
  }
}

const CheckinService = new CreateCheckinService();

export { CheckinService };

import Compressor from 'compressorjs';

export const compressImageFile = (file: File | Blob, options = {}) => {
  return new Promise<File>((resolve, reject) => {
    const compressOption = {
      quality: 0.8, // Quality
      maxWidth: 1920, // Full HD
      maxHeight: 1080,
      ...options,
    };
    new Compressor(file, {
      ...compressOption,
      success(result: any) {
        resolve(result);
      },
      error(err: any) {
        console.log(err.message, file);
        reject(null);
      },
    });
  });
};

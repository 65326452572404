import { TMissionUser } from "@/types";
import API, { ApiResponse } from "../config";

type TMissionStatus = {
  status: boolean;
  errorMessage?: string;
  urlRedirect?: string;
};

class CreateMissionService {
  async getMyMissionList() {
    return await API.GET<ApiResponse<TMissionUser[]>>("/missions/me");
  }

  async claimMission(missionUserId: string) {
    return await API.PATCH<ApiResponse<TMissionUser>>(`/missions/me/mission/claim/${missionUserId}`);
  }

  async successMission(missionUserId: string) {
    return await API.PATCH<ApiResponse<TMissionUser>>(`/missions/me/mission/success/${missionUserId}`);
  }

  async checkMissionStatus(missionId: string) {
    return await API.GET<ApiResponse<TMissionStatus>>(`/mission-status/${missionId}`);
  }
}

const MissionService = new CreateMissionService();

export { MissionService };

import { NESTED_ROUTES } from "@/const/nestedRoute.const";
import { useState } from "react";

type TNestedRoute = {
  label: string;
  path: string;
  icon: React.ReactNode;
};

export function BottomBar({ onChange }: { onChange?: (item: TNestedRoute) => void }) {
  const [active, setActive] = useState(NESTED_ROUTES[0].label);

  const onClick = (item: TNestedRoute) => {
    setActive(item.label);
    onChange && onChange(item);
  };

  return (
    <div className="fixed bottom-0 w-full h-28 bg-main flex items-top justify-around z-50">
      {NESTED_ROUTES.map((route) => (
        <div
          key={route.label}
          onClick={() => onClick(route)}
          className={`${
            active === route.label && "text-white border-t-2 border-white border-solid"
          } flex flex-col items-center justify-center space-y-2 p-6 text-[#757575] cursor-pointer`}
        >
          {route.icon}
          {active === route.label && <span className="text-xs">{route.label}</span>}
        </div>
      ))}
    </div>
  );
}

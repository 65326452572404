import React, { ReactNode, useEffect, useRef } from "react";

export default function Drawer({
  children,
  isOpen,
  setIsOpen,
  isCloseable = true,
  className,
}: {
  isOpen?: boolean;
  setIsOpen?: () => void;
  children: ReactNode;
  isCloseable?: boolean;
  className?: string;
}) {
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (isCloseable) {
        if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
          setIsOpen && setIsOpen();
        }
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div
      ref={drawerRef}
      className={`fixed left-0 right-0 bottom-0 h-[70vh] px-5 py-6 bg-main rounded-tl-[24px] rounded-tr-[24px] transition-all duration-700 overflow-scroll z-[999] ${
        isOpen ? "translate-y-0" : "translate-y-full"
      }
      ${className}
      `}
    >
      {children}
    </div>
  );
}

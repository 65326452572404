import { Button } from "@/components/common";
import LoadingButton from "./LoadingButton";

function LoadingClaim({
  loading,
  handleSuccessMission,
  isCallingServer,
}: {
  loading: boolean;
  handleSuccessMission: any;
  isCallingServer: boolean;
}) {
  if (loading) {
    return <LoadingButton />;
  } else {
    if (isCallingServer) {
      return (
        <Button
          onClick={handleSuccessMission}
          size="sm"
          className="!bg-[#99FF48] rounded-xl ml-3 cursor-not-allowed"
          disabled
        >
          <span className="text-small leading-small text-black">Claim</span>
        </Button>
      );
    } else {
      return (
        <Button onClick={handleSuccessMission} size="sm" className="!bg-[#99FF48] rounded-xl ml-3">
          <span className="text-small leading-small text-black">Claim</span>
        </Button>
      );
    }
  }
}

export default LoadingClaim;

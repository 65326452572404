import { Button, CircleLoading, CommunitiesCard } from "@/components/common";
import { HOME } from "@/const/path.const";
import { CommunityService } from "@/services";
import { TCommunity } from "@/types/community.type";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function OnboardCheckPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [communities, setCommunities] = useState<TCommunity[]>([]);

  const fetchCommunities = async () => {
    const res = await CommunityService.onBoardCommunity();
    if (res.success) {
      setCommunities(res.metadata);
      setLoading(false);
    } else {
      alert("Something went wrong, please try again later!");
      navigate(HOME.ROOT);
    }
  };

  useEffect(() => {
    fetchCommunities();
  }, []);

  return loading ? (
    <section className="flex flex-col items-center justify-center w-full h-screen">
      <h1 className="text-medium text-gray-1 w-56 text-center">Let’s see which communities you belong to</h1>
      <CircleLoading className="mt-8" />
    </section>
  ) : (
    <section
      className="w-full h-screen !bg-fixed relative overflow-auto"
      style={{ background: "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(65,45,35,1) 100%)" }}
    >
      <main className="flex flex-col p-5 h-screen w-full">
        <div className="flex-1 overflow-auto">
          <div className="flex items-center justify-center">
            <div className="w-64 space-y-3 text-center">
              <h1 className="text-base leading-base font-medium">Superrr!!</h1>
              <p className="text-gray-1">You’re member of those amazing communities</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-5">
            {communities &&
              communities.length > 0 &&
              communities.map((item) => <CommunitiesCard key={item.id} item={item} />)}
          </div>
        </div>
        <div className="h-28 mt-5">
          <Button className="justify-center w-full !bg-main" onClick={() => navigate(HOME.ROOT)}>
            Continue
          </Button>
        </div>
      </main>
    </section>
  );
}

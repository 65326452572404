import { Button } from "@/components/common";
import { HOME } from "@/const/path.const";
import { useStore } from "@/store/useStore";
import { MissionStatusEnum, MissionTypeEnum, TMissionUser } from "@/types";
import { TreknLogoImg } from "public/Images";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { MissionCard } from "../components";

export function MissionPage() {
  const { user, MyMissionList, fetchMyMissionList, points, prePoints } = useStore();
  const [sortedMission, setMission] = useState<TMissionUser[]>([]);
  const CONNECT_ID = "cm0c03wk00047k1w0cpd637kf";
  const CONNECT_WALLET_ID = "cm0uynwed001e12909hv5uiqy";
  useEffect(() => {
    if (user) {
      fetchMyMissionList();
    }
  }, [user?.id]);

  useEffect(() => {
    const noXMission = MyMissionList.filter((item) => item.id !== CONNECT_ID);
    const connectWalletMission = noXMission.filter((item) => item.id === CONNECT_WALLET_ID);
    const dailyMission = noXMission.filter(
      (item) =>
        (item.detail.type === MissionTypeEnum.DAILY || item.detail.type === MissionTypeEnum.IN_APP) &&
        item.status === MissionStatusEnum.PENDING,
    );
    const successMission = noXMission.filter(
      (item) => !dailyMission.includes(item) && item.status === MissionStatusEnum.SUCCESS && item.id !== CONNECT_WALLET_ID,
    );
    const pendingMission = noXMission.filter(
      (item) => !dailyMission.includes(item) && !successMission.includes(item) && item.id !== CONNECT_WALLET_ID,
    );
    setMission([...dailyMission, ...connectWalletMission, ...pendingMission, ...successMission]);
  }, [MyMissionList]);
  return (
    <section className="min-h-fullScreen relative">
      <div
        className="absolute top-0 w-full h-fullScreen"
        style={{ background: "linear-gradient(180deg, rgba(43,78,31,1) 0%, rgba(0,0,0,1) 10rem)" }}
      />
      <div className="absolute w-full h-fullScreen p-4 overflow-auto">
        {/* <BackButton /> */}
        <div className="flex items-center justify-between">
          <h1 className="text-base leading-base font-medium my-6">Quests</h1>
          <Link to={HOME.MY_POINTS}>
            <Button className="h-12 justify-center !bg-[#3D3D3D]/50 !rounded-full">
              <div className="flex items-center space-x-2">
                <p>
                  <CountUp start={prePoints} end={points || 0} duration={1} decimals={points ? 0 : 1} />
                </p>
                <img src={TreknLogoImg} alt="Trekn" className="w-6 h-6" />
              </div>
            </Button>
          </Link>
        </div>
        <div className="space-y-3">
          {sortedMission.map((mission) => (
            <MissionCard key={mission.id} mission={mission} />
          ))}
        </div>
      </div>
    </section>
  );
}

import React, { ChangeEvent, useEffect } from "react";

interface InputProps {
  label: React.ReactNode;
  defaultValue?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({ label, defaultValue, onChange }) => {
  const [defaultInput] = React.useState(defaultValue);

  useEffect(() => {
    onChange({ target: { value: defaultInput } } as ChangeEvent<HTMLInputElement>);
  }, []);

  return (
    <div className="flex flex-col">
      <label className="mb-2 text-gray-700 font-medium">{label}</label>
      <input
        type="text"
        defaultValue={defaultInput}
        onChange={onChange}
        className="bg-transparent border border-gray-700 rounded-md p-2 focus:border-blue-500 focus:outline-none"
      />
    </div>
  );
};

export { Input };

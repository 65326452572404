import { THREE_HOURS } from "@/const/app.const";
import { PostService, TimeService } from "@/services";
import dayjs from "dayjs";
import { ImmerStateCreator } from "./useStore";

export type PointsSlice = {
  pointThisPost: number;
  POINTS_PER_CLAIM: number;
  secondsToClaim: number;
  canClaim: boolean;
  postId: string | null;
  calculateTimeClaim: (post_id: string) => Promise<void>;
  onClaim: () => void;
  setCanClaim: (canClaim: boolean) => void;
  setPostId: (postId: string | null) => void;
};

export const createPointsSlice: ImmerStateCreator<PointsSlice> = (set) => ({
  pointThisPost: 0,
  POINTS_PER_CLAIM: 300,
  secondsToClaim: 0,
  canClaim: false,
  postId: null,

  onClaim: async () => {
    set((state) => {
      state.canClaim = false;
      state.pointThisPost = 0;
      state.secondsToClaim = 0;
      state.postId = null;
    });
  },

  setCanClaim: (canClaim: boolean) => {
    set((state) => {
      state.canClaim = canClaim;
    });
  },

  setPostId: (postId: string | null) => {
    set((state) => {
      state.postId = postId;
    });
  },

  calculateTimeClaim: async (post_id: string | null) => {
    if (!post_id) {
      return set((state) => {
        state.secondsToClaim = 0;
        state.pointThisPost = 0;
        state.canClaim = false;
      });
    }

    const timeServer = await TimeService.getTimeSever();
    const post = await PostService.getPostById(post_id);

    if (dayjs(timeServer).isAfter(dayjs(post.metadata.endAt))) {
      return set((state) => {
        state.secondsToClaim = 0;
        state.pointThisPost = 300;
        state.canClaim = true;
      });
    } else {
      const secondToClaim = dayjs(post.metadata.endAt).diff(dayjs(timeServer), "second");
      const pointThisPost = (300 / THREE_HOURS) * (THREE_HOURS - secondToClaim);

      set((state) => {
        state.secondsToClaim = secondToClaim;
        state.pointThisPost = pointThisPost;
        state.canClaim = secondToClaim <= 0;
      });
    }
  },
});

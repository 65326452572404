import { FRIEND_CONFIRM_STATUS, REF_TYPE } from "@/const/app.const";
import { PostService } from "@/services";
import { useStore } from "@/store/useStore";
import { TPost } from "@/types";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { ButtonHeader, FriendsPosts, MyPosts, PostToday, PublicPosts } from "../components";
import { AcceptFriendModal } from "../components/AcceptFriendModal";
import WebApp from "@twa-dev/sdk";
import WhitelistBanner from "../components/Event/WhitelistBanner";

export function HomePage() {
  // const showPopup = useShowPopup();
  // const { walletAddress } = useConnectWalletContext();
  const [claimPost, setClaimPost] = useState<TPost[]>([]);
  const {
    fetchHomePosts,
    fetchMyMissionList,
    HomeAllPost,
    HomeMyPost,
    HomeFriendPost,
    HomeLoading: loading,
    user,
  } = useStore();
  const [openFriendModal, setOpenFriendModal] = useState(false);

  const checkReferral = async () => {
    const rf = localStorage.getItem("refFriend");
    if (rf) {
      const rf_data = JSON.parse(rf);
      if (
        rf_data.type === REF_TYPE.USER &&
        user!.id !== rf_data.id &&
        rf_data.status === FRIEND_CONFIRM_STATUS.PENDING
      ) {
        setOpenFriendModal(true);
      }
    }
  };

  useEffect(() => {
    ReactGA.initialize("G-C85G9C75BC");
    // ReactGA.send({ hitType: "pageview", page: "/", title: "test metrics" });
    ReactGA.event({
      category: "App Engagement",
      action: "open_app",
      label: "App opened from home screen",
    });

    if (WebApp.initDataUnsafe.start_param?.split("_")[0] === "from") {
      ReactGA.event({
        category: "App Engagement",
        action: "open_from_notification",
        label: "Open from notification",
      });
    }

    if (localStorage.getItem("refFriend") && user) {
      checkReferral();
    }
  }, [user]);

  const getClaimPost = async () => {
    const post = await PostService.getPostInDayAndUnclaim();
    setClaimPost(post.metadata);
  };

  useEffect(() => {
    fetchHomePosts();
    getClaimPost();
    fetchMyMissionList();
  }, []);

  return (
    <section className="relative w-full h-screen">
      <div
        className="absolute w-full h-screen"
        style={{ background: "linear-gradient(180deg, rgba(43,78,31,1) 0%, rgba(0,0,0,1) 10rem)" }}
      />
      <div className="absolute p-4 w-full h-screen overflow-auto pb-28">
        <ButtonHeader />
        <div className="pt-12">
          <PostToday post={claimPost} />
        </div>
        <WhitelistBanner />
        <div className="my-8">
          <div className="mt-8 space-y-3">
            <PublicPosts items={HomeAllPost} loading={loading} />
            <MyPosts items={HomeMyPost} />
            <FriendsPosts items={HomeFriendPost} />
          </div>
        </div>
      </div>
      <AcceptFriendModal open={openFriendModal} setOpen={setOpenFriendModal} />
    </section>
  );
}

import { Avatar } from "@/components/common";
import { useStore } from "@/store/useStore";
import { useEffect } from "react";

export function UserInfo() {
  const { user, fetchUser } = useStore();

  useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [user]);

  return (
    <div className="mt-8 mx-2">
      <div className="flex space-x-4">
        <Avatar type="square" src={user?.avatar} className="w-[6.25rem] h-[6.25rem] object-cover" />
        <div className="flex flex-col justify-center">
          <h1 className="text-xl">{user?.name}</h1>
          <p className="text-secondary text-sm mt-2">{user?.username}</p>
        </div>
      </div>
    </div>
  );
}

import { CircleLoading, InfiniteScroll } from "@/components/common";
import { FriendRefService } from "@/services";
import { useStore } from "@/store/useStore";
import { TUser } from "@/types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

export default function FriendComponent() {
  const { user } = useStore();

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [refList, setRefList] = useState<TUser[]>([]);

  const fetchRef = async () => {
    if (user) {
      const getReferralList = await FriendRefService.getFriendList({ page, limit: 10 });
      setTotalPage(getReferralList.metadata.pagination.total);
      setRefList(getReferralList.metadata.data.flatMap((data) => data.friend));
    }
  };

  useEffect(() => {
    fetchRef();
  }, [user]);
  return (
    <div>
      <div className="flex flex-col">
        {!refList.length && (
          <p className="text-small leading-small text-[#5A5A5A] text-center mt-[20vh]">
            Add a friend to fill in this space
          </p>
        )}
        <InfiniteScroll
          loader={
            <div className="flex items-center justify-center w-full">
              <CircleLoading className="mt-8" />
            </div>
          }
          hasMore={refList.length < totalPage}
          fetchMore={() => setPage((pre) => pre + 1)}
        >
          {refList.map((friend: TUser) => (
            <div className="flex items-center justify-between" key={friend.id}>
              <div className="p-1 flex items-center gap-2">
                <div className="w-12 h-12 rounded-full bg-gray-500">
                  {friend.avatar && <img alt="avatar" className="w-full h-full rounded-full" src={friend.avatar} />}
                </div>
                <p className="text-medium leading-medium text-white">{friend.name}</p>
              </div>
              <div
                className="p-[14px]"
                onClick={() => {
                  // handle remove friend
                }}
              >
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}

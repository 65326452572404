import { CircleLoading, InfiniteScroll } from "@/components/common";
import { TPost } from "@/types";

function BreakpointPost({ posts, hasMore, onLoadMore }: { posts: TPost[]; hasMore: boolean; onLoadMore: () => void }) {
  return (
    <InfiniteScroll
      loader={
        <div className="flex items-center justify-center w-full">
          <CircleLoading className="mt-8" />
        </div>
      }
      hasMore={hasMore}
      fetchMore={onLoadMore}
      className="grid grid-cols-3 gap-2"
    >
      {posts &&
        posts.length > 0 &&
        posts.map((item) => (
          <div>
            <img className="h-[118px] w-full object-cover" src={item.image} alt={item.content} />
          </div>
        ))}
    </InfiniteScroll>
  );
}

export default BreakpointPost;

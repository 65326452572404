export const HEADER_DEFAULT = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const BASE_SOURCE = process.env.REACT_APP_API_URL || "http://localhost:3005/api/v1";
export const PRICE_FEED_SOURCE = "/price-feed-service/";
export const CHECK_NFT_SOURCE = "/check-nft-service";

export const TIMEOUT = 30000;

// HTTP Status
export const STT_OK = 200;
export const STT_CREATED = 201;
export const STT_BAD_REQUEST = 400;
export const STT_UNAUTHORIZED = 401;
export const STT_FORBIDDEN = 403;
export const STT_NOT_FOUND = 404;
export const STT_INTERNAL_SERVER = 500;
export const STT_NOT_MODIFIED = 304;

// API Path

// Authentication APIs
export const AUTH = "/auth";

export const GET_NONCE = `${AUTH}/wallet/{walletAddress}/nonce`;
export const POST_LOGIN = `${AUTH}/{network}/login`;
export const POST_LOGOUT = `${AUTH}/logout`;

// Offer APIs
export const OFFERS = "/offers";
export const GET_OFFER_TEMPLATES = `${OFFERS}/templates`;

// Price feed APIs
export const GET_LATEST_PRICE_FEEDS = "/latest_price_feeds";

// Lend APIs
export const GET_LEND_OFFERS = "/offers";
export const GET_LEND_OFFERS_DASHBOARD = `${GET_LEND_OFFERS}/dashboard`;

// Loan APIs
export const GET_CONTRACT = "/loans";
export const GET_LOANS_DASHBOARD = `${GET_CONTRACT}/dashboard`;

export const POST_NOTIFICATION_SETTING = "/notification-setting";

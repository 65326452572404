import { TCommunity, TUser } from "@/types";
import { ImmerStateCreator } from "./useStore";
import { CommunityService } from "@/services";

export type LeaderboardSlice = {
  CommunityLeaderboard: TCommunity[];
  UserLeaderboard: { top10: TUser[]; myRank: number | null };
  LeaderboardLoading: boolean;
  fetchLeaderboard: () => Promise<void>;
  fetchUserLeaderboard: () => Promise<void>;
};

export const createLeaderboardSlice: ImmerStateCreator<LeaderboardSlice> = (set) => ({
  CommunityLeaderboard: [],
  UserLeaderboard: { top10: [], myRank: null },
  LeaderboardLoading: false,

  fetchLeaderboard: async () => {
    set((state) => {
      state.LeaderboardLoading = true;
    });
    const Communities = await CommunityService.getLeaderboard();

    set((state) => {
      state.CommunityLeaderboard = Communities.metadata;
      state.LeaderboardLoading = false;
    });
  },

  fetchUserLeaderboard: async () => {
    set((state) => {
      state.LeaderboardLoading = true;
    });
    const Users = await CommunityService.getUserLeaderboard();

    set((state) => {
      state.UserLeaderboard = Users.metadata;
      state.LeaderboardLoading = false;
    });
  },
});

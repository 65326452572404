import { useEffect, useRef } from "react";

type InfiniteScrollProps = {
  children: React.ReactNode;
  loader: React.ReactNode;
  fetchMore: () => void;
  hasMore: boolean;
  endMessage?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export function InfiniteScroll({
  hasMore,
  fetchMore,
  className,
  style,
  loader,
  endMessage,
  children,
}: InfiniteScrollProps) {
  const pageEndRef = useRef(null);
  useEffect(() => {
    if (hasMore) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchMore();
        }
      });

      if (pageEndRef.current) {
        observer.observe(pageEndRef.current);
      }

      return () => {
        if (pageEndRef.current) {
          observer.unobserve(pageEndRef.current);
        }
      };
    }
  }, [hasMore]);
  return (
    <div style={style}>
      <div className={className}>{children}</div>
      {hasMore ? <div ref={pageEndRef}>{loader}</div> : endMessage}
    </div>
  );
}

import { Card } from "@/components/common";

export function ListNFT({ item }: { item: any[] }) {
  return (
    <div className="mt-6 grid grid-cols-2 gap-3">
      {item.map((nft) => (
        <Card type="image">
          <img src={nft.image_uri} alt="NFT" className="w-full h-full rounded-xl" />
        </Card>
      ))}
    </div>
  );
}

import { HorizontalScroll, SkeletonSmallPost, SmallPost } from "@/components/common";
import { CommunityEnum } from "@/const/app.const";
import { EXPLORE } from "@/const/path.const";
import { TPost } from "@/types";
import { Link } from "react-router-dom";

export function PublicPosts({ items, loading }: { items: TPost[]; loading: boolean }) {
  return items && items.length > 0 ? (
    <div>
      <div className="flex items-center justify-between text-small py-2">
        <p className="text-gray-1">Explore moments</p>
        <Link to={`${EXPLORE.COMMUNITIES}?type=${CommunityEnum.PUBLIC}`}>View all</Link>
      </div>

      <HorizontalScroll>
        {loading && <SkeletonSmallPost />}
        {items?.map((item) => (
          <SmallPost key={item.id} item={item} />
        ))}
      </HorizontalScroll>
    </div>
  ) : null;
}

import { TreknLogoImg } from "public/Images";
import styles from "./TreknLogo.module.css";

export function TreknLogo({ loading = false, className }: { loading?: boolean; className?: string }) {
  return (
    <div
      className={`${styles.loader} ${
        !loading && styles.cancel_loader
      } transition-all duration-300 ease-linear ${className}`}
    >
      <img src={TreknLogoImg} alt="Trekn Logo" className="w-[110px] absolute" />
    </div>
  );
}

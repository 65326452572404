import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export default function CaptureHeader() {
  const navigate = useNavigate();
  return (
    <div className="relative py-2.5">
      <div onClick={()=>navigate('/')}>
        <FontAwesomeIcon icon={faTimes} className={"w-5 h-5 absolute left-5 top-1/2 -translate-y-1/2"} />
      </div>
      <p className="text-center font-bold text-medium leading-medium">Capture your moment</p>
    </div>
  );
}

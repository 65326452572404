import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/common";
import { CheckinService } from "@/services";
import { useStore } from "@/store/useStore";
import { BGCheckin, TreknLogoImg } from "public/Images";
import { HOME } from "@/const/path.const";
import { useWindowSize } from "@/hooks/common-hooks";
import Confetti from "react-confetti";

interface CheckinItemProps {
  day: number;
  checked: boolean;
  current: boolean;
  prize?: string | null;
}

const CheckinItem: React.FC<CheckinItemProps> = ({ day, checked, current, prize }) => (
  <div
    className={`flex flex-col items-center justify-center p-3 gap-3 
      ${checked ? "bg-[#4D4D4D0D]" : "bg-[#FFFFFF0D]"} 
      ${current ? "border border-[#99FF48]" : ""} rounded-xl`}
  >
    <img
      src={TreknLogoImg}
      className={`w-4 h-4 rounded-full ${!checked ? "border border-[#FFE248]" : ""}`}
      alt="Trekn-logo"
    />
    <p className={`${checked ? "text-[#B3B3B3] text-small leading-small font-bold" : "text-white"}`}>
      {prize ? `+${prize}` : "???"}
    </p>
    <p className={`${checked ? "text-[#B3B3B3] text-small leading-small" : "text-[#FFFFFFB2]"}`}>Day {day}</p>
  </div>
);

const CheckinPage: React.FC = () => {
  const { user, fetchUser } = useStore();
  const { windowHeight, windowWidth } = useWindowSize();
  const [currentView, setCurrentView] = useState("check-in");
  const [prizeList, setPrizeList] = useState<any[]>([]);
  const [prize, setPrize] = useState<any>(null);
  const [checkInDay, setCheckinDay] = useState(1);
  const [confettiOpen, setConfettiOpen] = useState(false);
  const [recycleConfetti, setRecycleConfetti] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const prizeData = await CheckinService.getPrize();
        setPrizeList(prizeData.metadata);

        const checkinData = await CheckinService.checkIsCheckedIn(user?.id!);
        const isChecked = checkinData.metadata.checked;
        if (isChecked || (Number(checkinData.metadata.data?.day) > prizeData.metadata.length)) {
          navigate(HOME.ROOT);
        }
        if (checkinData.metadata.data?.day) {
          setCheckinDay(Number(checkinData.metadata.data.day) + 1);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [user?.id]);

  useEffect(() => {
    if (currentView === "done") {
      setConfettiOpen(true);
      setRecycleConfetti(true);

      setTimeout(() => {
        setRecycleConfetti(false);
      }, 2000);

      setTimeout(() => {
        setConfettiOpen(false);
      }, 4000);
    }
  }, [currentView]);

  const submitCheckin = async () => {
    try {
      await CheckinService.checkin(user?.id!);
      await fetchUser();
      setPrize(prizeList[checkInDay - 1]?.prize);
      setCurrentView("done");
    } catch (error) {
      console.error("Error during check-in", error);
    }
  };

  const renderCheckinItems = () =>
    prizeList.map(({ prize }, idx) => (
      <CheckinItem
        key={idx}
        prize={prize}
        current={idx + 1 === checkInDay}
        day={idx + 1}
        checked={idx + 1 < checkInDay}
      />
    ));

  const renderDoneView = () => (
    <>
      {confettiOpen && <Confetti width={windowWidth} height={windowHeight} recycle={recycleConfetti} />}
      <div className="absolute top-[32%] left-1/2 -translate-x-1/2">
        <img src={TreknLogoImg} className="w-8 h-8 mx-auto mb-6" alt="Trekn-logo" />
        {checkInDay !== 12 ? (
          <>
            <p className="text-[#D8CFC5] text-[36px] font-semibold leading-[50.4px] w-screen text-center mb-3">
              <span className="text-[#D8CFC580]">+</span>
              {prize} points
            </p>
            <p className="text-[#D8CFC5B2] text-center max-w-[215px] mx-auto">
              Probably nothing much to expect at the day 12th ;)
            </p>
          </>
        ) : (
          <>
            <div className="text-center">
              <p className="bg-gradient-to-r from-[#00FC98] to-[#30A8FF] inline-block text-transparent bg-clip-text text-[36px] leading-[50.4px] mb-3 text-center font-semibold">
                Special
              </p>
            </div>
            <p className="text-[#D8CFC5B2] max-w-[215px] mx-auto text-center">
              You just received a WL spot.
              <br />
              Check your wallet!
            </p>
          </>
        )}
      </div>
    </>
  );

  return (
    <section className="w-full h-screen relative">
      <div
        className="fixed w-full h-full -z-10"
        style={{
          background: "linear-gradient(0deg, #FF854033 0%,#00000000 30%)",
        }}
      />
      <img
        src={BGCheckin}
        alt="BGCheckin"
        className={`absolute bottom-0 object-cover w-full -z-20 ${currentView === "done" ? "block" : "hidden"}`}
        style={{
          background: "linear-gradient(180deg, #191716 -3.92%, rgba(0, 0, 0, 0) 37.65%)",
        }}
      />
      {currentView === "check-in" && (
        <div className="p-5">
          <div className="flex flex-col justify-center items-center mb-7">
            <p className="text-heading-base leading-heading-base font-semibold text-white">Checkin Day {checkInDay}</p>
            <p className="text-[#8E8E93] text-medium leading-medium mt-3">Claim your daily checkin reward</p>
          </div>
          <div className={`grid grid-cols-3 gap-3 max-h-[490px] overflow-auto ${windowHeight < 651 && "pb-20"}`}>
            {renderCheckinItems()}
          </div>
        </div>
      )}
      {currentView === "done" && renderDoneView()}
      <div className="fixed w-full bottom-14 z-30 px-5 mt-10">
        <Button
          onClick={() => (currentView === "done" ? navigate(HOME.ROOT) : submitCheckin())}
          className="w-full justify-center bg-[#1E1E1E]"
        >
          {currentView !== "done" ? "Continue" : "Got it"}
        </Button>
      </div>
    </section>
  );
};

export default CheckinPage;

import { useStore } from "@/store/useStore";
import { useEffect } from "react";
import { LeaderBoardCard } from "./LeaderBoardCard";

export default function UserLeaderboard() {
  const { UserLeaderboard, fetchUserLeaderboard, user } = useStore();

  useEffect(() => {
    fetchUserLeaderboard();
  }, []);
  return (
    <div className="pb-8 px-4 overflow-y-auto">
      <div className="space-y-3">
        {UserLeaderboard.top10.map((item, index) => (
          <LeaderBoardCard key={item.id} icon={item.avatar} name={item.name} points={item.points} index={index + 1} />
        ))}
        {UserLeaderboard.myRank && (
          <>
            <p className="text-xl text-center">...</p>
            <LeaderBoardCard
              icon={user!.avatar}
              name={user!.name}
              points={user!.points}
              index={UserLeaderboard.myRank}
            />
          </>
        )}
      </div>
    </div>
  );
}

import { Button, CircleLoading, CommunitiesCard, InfiniteScroll } from "@/components/common";
import { EXPLORE } from "@/const/path.const";
import { CommunityService, PostService } from "@/services";
import { TCommunity } from "@/types/community.type";
import { useEffect, useState } from "react";
import { FaRankingStar } from "react-icons/fa6";
import { Link } from "react-router-dom";

export function ExplorePage() {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [communities, setCommunities] = useState<TCommunity[]>([]);

  const fetchCommunities = async () => {
    const res = await CommunityService.getAllCommunity({ page, limit: 10 });
    setTotalPage(res.metadata.pagination.total);
    setCommunities((prev) => [...prev, ...res.metadata.data]);
  };

  useEffect(() => {
    fetchCommunities();
  }, [page]);

  return (
    <section className="py-6">
      <div className="fixed top-0 w-full px-5">
        <div className="flex items-center justify-between pt-6 pb-4 bg-main-background">
          <h1 className="text-base font-semibold">Explore</h1>
          <Link to={EXPLORE.LEADER_BOARD}>
            <Button className="!bg-secondary/40 !rounded-full" icon={<FaRankingStar size={20} />}>
              Leaderboard
            </Button>
          </Link>
        </div>
      </div>
      <div className="mt-24 px-5">
        <InfiniteScroll
          loader={
            <div className="flex items-center justify-center w-full">
              <CircleLoading className="mt-8" />
            </div>
          }
          hasMore={communities.length < totalPage}
          fetchMore={() => setPage((prev) => prev + 1)}
          className="grid grid-cols-2 gap-3"
        >
          {communities &&
            communities.length > 0 &&
            communities.map((item) => (
              <Link key={item.id} to={`${EXPLORE.FANPAGE}/${item.id}`}>
                <CommunitiesCard item={item} />
              </Link>
            ))}
        </InfiniteScroll>
      </div>
    </section>
  );
}

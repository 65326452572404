import { Button } from "@/components/common";
import { ACCOUNT, FRIEND, HOME } from "@/const/path.const";
import { useStore } from "@/store/useStore";
import { TreknLogoImg } from "public/Images";
import { FaEllipsisH, FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";

export function HeaderButton() {
  const { points } = useStore();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("rf");
    window.location.reload();
  };

  return (
    <div className="mt-2 flex items-center justify-between">
      <Link to={FRIEND.ROOT}>
        <Button className="w-12 h-12 justify-center !bg-button/40 !rounded-full" icon={<FaUsers size={20} />} />
      </Link>
      <div className="flex space-x-3">
        <Link to={HOME.MY_POINTS}>
          <Button className="h-12 justify-center !bg-button/40 !rounded-full">
            <div className="flex items-center space-x-2">
              <p>{points}</p>
              <img src={TreknLogoImg} alt="Trekn" className="w-6 h-6" />
            </div>
          </Button>
        </Link>
        <Link to={ACCOUNT.UPDATE_INFO}>
          <Button
            // onClick={handleLogout}
            className="w-12 h-12 justify-center !bg-button/40 !rounded-full"
            icon={<FaEllipsisH size={20} />}
          />
        </Link>
      </div>
    </div>
  );
}

import { CommunityService } from "@/services/community/community.service";
import { useStore } from "@/store/useStore";
import { TCommunity } from "@/types";
import React, { useEffect, useState } from "react";

export default function CommunityComponent() {
  const [communityList, setCommunityList] = useState<TCommunity[] | undefined>(undefined);
  const { user } = useStore();

  const getDate = (date: string | Date) => {
    const _date = new Date(date);
    const day = _date.getDate();
    const month = _date.toLocaleString("default", { month: "short" });

    const getOrdinalSuffix = (day: number) => {
      if (day >= 11 && day <= 13) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;
    const result = `${month} ${dayWithSuffix}`;

    return result;
  };

  useEffect(() => {
    (async () => {
      if (user?.id) {
        const communities: any = await CommunityService.getCommunityByUserId(user?.id);
        console.log(communities);
        setCommunityList(communities);
      }
    })();
  }, [user]);
  return (
    <>
      {!communityList?.length ? (
        <p className="text-small leading-small text-[#5A5A5A] text-center mt-[20vh]">
          Add a friend to fill in this space
        </p>
      ) : (
        <>
          {communityList.map((community: any, idx: number) => (
            <div className="flex items-center justify-between" key={idx}>
              <div className="p-1 flex items-center gap-2">
                <div className="w-12 h-12 rounded-lg">
                  {community.avatar && <img className="w-full h-full rounded-lg" src={community.avatar} />}
                </div>
                <p className="text-medium leading-medium text-white">{community.name}</p>
              </div>
              <p className="text-[#FFFFFFB2] text-small leading-small">Since {getDate(community.createdAt)}</p>
            </div>
          ))}
        </>
      )}
    </>
  );
}

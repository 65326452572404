import React from "react";
import styles from "./styles.module.css";

export function HorizontalScroll({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
  styles?: React.CSSProperties;
}) {
  return <div className={`${className} ${styles.scroll_parent} space-x-3`}>{children}</div>;
}

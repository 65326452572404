import { BackButton, Input, LinearButton } from "@/components/common";
import { storageAndUpdateWallet, useCreateWallet } from "@/hooks";
import { useStore } from "@/store/useStore";
import { useState } from "react";
import RenderSeedPhrase from "./components/RenderSeedPhrase";

export function CreateWallet() {
  const { user } = useStore();
  const { seedPhrase, wallet } = useCreateWallet();
  const [walletName, setWalletName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleCreateWallet = async () => {
    if (!wallet) return;
    setLoading(true);
    const success = await storageAndUpdateWallet(wallet, walletName);
    if (success) {
      setLoading(false);
      window.location.href = "/";
    } else {
      setLoading(false);
      alert("Something went wrong! Please try again.");
    }
  };

  return (
    <main className="h-screen relative">
      <div className="p-4">
        <div className="flex relative w-full h-12 justify-center items-center">
          <BackButton className="absolute left-0" />
          <h1 className="text-white text-xl font-bold">Create Wallet</h1>
        </div>
        <div className="mt-6">
          {user && (
            <div className="space-y-4 overflow-auto pb-20">
              <Input
                defaultValue={`Wallet ${user.list_wallet.length + 1}`}
                label={<span className="text-white">Wallet Name:</span>}
                onChange={(e) => setWalletName(e.target.value)}
              />
              <p className="font-medium">Seed Phrase:</p>
              <RenderSeedPhrase seedPhrase={seedPhrase} />
            </div>
          )}
        </div>
      </div>
      <div className="fixed w-full h-24 bg-main-background bottom-0 flex items-center justify-center">
        <LinearButton
          loading={loading}
          disabled={loading}
          onClick={handleCreateWallet}
          className="items-center justify-between rounded !px-9"
        >
          Create and Connect Wallet
        </LinearButton>
      </div>
    </main>
  );
}

import { CircleLoading } from "@/components/common";
import { AUTH } from "@/const/path.const";
import { PostService } from "@/services";
import { useStore } from "@/store/useStore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HeaderButton, ListNFT, UserInfo } from "./components";

export function AccountPage() {
  const { user } = useStore();
  const [listNFT, setListNFT] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (user?.list_wallet) {
        const result = await PostService.getListNft(user.list_wallet[0].address);
        setListNFT(result.nfts);
      }
      setLoading(false);
    })();
  }, [user]);
  return (
    <section className="p-4">
      <HeaderButton />
      <UserInfo />
      {/* <ListCommunity item={TagsList} /> */}
      {!user?.list_wallet ||
        (user.list_wallet.length === 0 && (
          <div className="flex flex-col items-center border border-dashed border-[#2C2C2C] mt-[50%] py-6 px-3">
            <p className="text-center text-small leading-small text-[#5A5A5A]">
              Connect wallet to sync your membership to Trekn
            </p>
            <Link
              to={AUTH.WALLET_OPTIONS.CONNECT}
              className="w-full bg-[#2C2C2C] flex items-center justify-center py-3 rounded-lg mt-5"
            >
              Connect Wallet
            </Link>
          </div>
        ))}
      {loading ? (
        <div className=" h-[40%] flex items-center justify-center mt-5">
          <CircleLoading />
        </div>
      ) : (
        <ListNFT item={listNFT} />
      )}
    </section>
  );
}

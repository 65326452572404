import { AUTH } from "@/const/path.const";
import { UserService } from "@/services/user/user.service";
import { useStore } from "@/store/useStore";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import WebApp from "@twa-dev/sdk";
import { ReactNode, createContext, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
require("@solana/wallet-adapter-react-ui/styles.css");

const ConnectWalletContext = createContext({});
export const useConnectWalletContext = () => useContext(ConnectWalletContext);

export const ConnectWalletProvider = ({ children }: { children: ReactNode }) => {
  const { user, fetchUser } = useStore();
  const network = WalletAdapterNetwork.Devnet;
  const endpoint = useMemo(() => clusterApiUrl("mainnet-beta"), [network]);
  const navigate = useNavigate();

  useEffect(() => {
    const handleStartParam = async () => {
      const data: string[] = WebApp.initDataUnsafe.start_param!.split("_");

      WebApp.initDataUnsafe.start_param = "";
      // setWalletAddress(data[1]);
      // localStorage.setItem("address", JSON.stringify(data[1]));
      // const login = await LoginService.login(data[1]);
      // if (login.metadata.access_token) {
      //   localStorage.setItem("token", JSON.stringify(login.metadata.access_token));
      // }
      if (!user?.address) {
        await UserService.updateUser({ address: data[1] });
        await fetchUser();
        navigate(AUTH.ONBOARD.CHECK);
      }
    };

    // const checkWalletAddress = async () => {
    //   const address = localStorage.getItem("address");
    //   if (address) {
    //     const data = await LoginService.login(JSON.parse(address));
    //     if (data.metadata.access_token) {
    //       localStorage.setItem("token", JSON.stringify(data.metadata.access_token));
    //     }
    //     try {
    //       await fetchUser();
    //     } catch (error) {
    //       localStorage.removeItem("address");
    //       localStorage.removeItem("token");
    //       navigate(AUTH.LOGIN);
    //     }

    //     // navigate("/");
    //     setWalletAddress(address);
    //   } else {
    //     navigate(AUTH.LOGIN);
    //   }
    // };
    if (WebApp.initDataUnsafe.start_param?.[0] === "w") {
      handleStartParam();
    }
    // if (WebApp.initDataUnsafe.start_param?.[0] === "from") {
    //   ReactGA.event({
    //     category: "App Engagement",
    //     action: "open_from_notification",
    //     label: "Open from notification",
    //   });
    // }
    // else if (!walletAddress && location.pathname !== ROUTE.CONNECT_WALLET) {
    //   checkWalletAddress();
    // }
  }, [user]);

  return (
    <ConnectWalletContext.Provider value={{}}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={[]}>
          <WalletModalProvider>{children}</WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ConnectWalletContext.Provider>
  );
};

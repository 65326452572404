export enum MissionStatusEnum {
  CLAIMED = "CLAIMED",
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
}

export enum MissionTypeEnum {
  POST = "POST",
  REACTION = "REACTION",
  DAILY = "DAILY",
  FOLLOW = "FOLLOW",
  COMMUNITY = "COMMUNITY",
  REDIRECT = "REDIRECT",
  REFERRAL = "REFERRAL",
  IN_APP = "IN_APP",
  CALL_CHECK = "CALL_CHECK",
}

export enum MissionScopeEnum {
  USER = "USER",
  ALLUSER = "ALLUSER",
}

export type TMissionDetail = {
  id: string;
  name: string;
  description: string;
  icon: string;
  points: number;
  linkRedirect: any;
  is_deleted: boolean;
  type: MissionTypeEnum;
  scope: MissionScopeEnum;
  createdAt: string;
  updatedAt: string;
};

export type TMissionUser = {
  id: string;
  userId: string;
  missionId: string;
  status: MissionStatusEnum;
  target: string | null;
  detail: TMissionDetail;
};

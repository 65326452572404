import { TTag } from "@/types";

function ButtonTag({ tag, selectedTags, onClick }: { tag: TTag; selectedTags: TTag[]; onClick: (tag: TTag) => void }) {
  return (
    <button
      onClick={() => onClick(tag)}
      className={`py-2 px-4 bg-[#FFFFFF1A] text-white rounded-full justify-center items-center ${ selectedTags.includes(tag) ? "ring ring-[#60F13B]" : ""} flex gap-2`}
    >
      <img src={tag.icon} className="object-contain w-4 h-4" alt={tag.name} />
      <span className="text-lg">{tag.name}</span>
    </button>
  );
}

export default ButtonTag;

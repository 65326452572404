import { BackButton, Button } from "@/components/common";
import { FaBell, FaGlobe, FaRegNewspaper, FaShare, FaShieldAlt, FaWrench } from "react-icons/fa";
import { SettingUserInfo } from "./components";

const SettingOptions = [
  { group: 1, items: [{ id: 1, title: "Manage post", icon: <FaRegNewspaper size={16} className="text-white/70" /> }] },
  {
    group: 2,
    items: [
      { id: 2, title: "Notification", icon: <FaBell size={16} className="text-white/70" /> },
      { id: 3, title: "Privacy", icon: <FaShieldAlt size={16} className="text-white/70" /> },
      { id: 4, title: "Timezone", icon: <FaGlobe size={16} className="text-white/70" /> },
      { id: 5, title: "Other", icon: <FaWrench size={16} className="text-white/70" /> },
    ],
  },
  { group: 3, items: [{ id: 6, title: "Share", icon: <FaShare size={16} className="text-white/70" /> }] },
];

export function SettingPage() {
  return (
    <section className="p-4">
      <BackButton />
      <SettingUserInfo />
      <div className="mt-11">
        {SettingOptions.map((group) => (
          <div key={group.group} className="mb-8">
            {group.items.map((item) => (
              <Button className="!bg-main !w-full mb-3" key={item.id} icon={item.icon}>
                {item.title}
              </Button>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
}
